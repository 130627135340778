import gql from 'graphql-tag';

const singlePatternOtherAttributesFragment = gql`
    fragment SinglePatternOtherAttributesFragment on OtherAttributesNode {
        id
        antiStatic
        antiStaticCertificateFile
        antiStaticCertificateFileExpires
        antiStaticNotExpires
        antiStaticConductive
        antiStaticConductiveCertificateFile
        antiStaticConductiveCertificateFileExpires
        antiStaticConductiveNotExpires
        antiBacterial
        antiBacterialCertificateFile
        antiBacterialCertificateFileExpires
        antiBacterialNotExpires
        bacterialResistance
        bacterialResistanceCertificateFile
        bacterialResistanceCertificateFileExpires
        bacterialResistanceNotExpires
        mildewResistance
        mildewResistanceMethod
        mildewResistanceCertificateFile
        mildewResistanceCertificateFileExpires
        mildewResistanceNotExpires
        pinkStainResistance 
        pinkStainResistanceCertificateFile
        pinkStainResistanceCertificateFileExpires
        pinkStainResistanceNotExpires
        cytotoxicity 
        cytotoxicityCertificateFile
        cytotoxicityCertificateFileExpires
        cytotoxicityNotExpires
        irritationAndSkinSensitization 
        irritationAndSkinSensitizationCertificateFile
        irritationAndSkinSensitizationCertificateFileExpires
        irritationAndSkinSensitizationNotExpires
        cleanability
        cleanibilityMethod 
        cleanabilityCertificateFile
        cleanabilityCertificateFileExpires
        cleanabilityNotExpires
        hydrocarbonResistance
        hydrocarbonResistanceMethod
        hydrocarbonResistanceMethodCertificateFile
        hydrocarbonResistanceMethodCertificateFileExpires
        hydrocarbonResistanceNotExpires
        oilResistance
        sulfideStaining
        sulfideStainingCertificateFile
        sulfideStainingCertificateFileExpires
        sulfideStainingNotExpires
        heavyMetals
        heavyMetalsCertificateFile
        heavyMetalsCertificateFileExpires
        heavyMetalsNotExpires
        phthalateFreeProduct
        phthalateFreeProductCertificateFile
        phthalateFreeProductCertificateFileExpires
        phthalateFreeProductNotExpires
        hydrolisis
        hydrolisisMethod
        hydrolisisMethodCertificateFile
        hydrolisisMethodCertificateFileExpires
        hydrolisisNotExpires
        heatBuildup
        heatBuildupMethod
        heatBuildupMethodCertificateFile
        heatBuildupMethodCertificateFileExpires
        heatBuildupNotExpires
        accousticalAttenuation 
        accousticalAttenuationMethod 
        accousticalAttenuationMethodCertificateFile
        accousticalAttenuationMethodCertificateFileExpires
        accousticalAttenuationNotExpires
        soundAbsorption
        soundAbsorptionMethod
        soundAbsorptionMethodCertificateFile
        soundAbsorptionMethodCertificateFileExpires
        soundAbsorptionNotExpires
        foamLamination
        thermoformed
        electricSealable 
        impermeable
        impermeableCertificateFile
        impermeableCertificateFileExpires
        impermeableNotExpires
        temperatureReductionTreatment
        temperatureReductionTreatmentCertificateFile
        temperatureReductionTreatmentCertificateFileExpires
        temperatureReductionTreatmentNotExpires
        denimStainResistance
        denimStainResistanceCertificateFile
        denimStainResistanceCertificateFileExpires
        denimStainResistanceNotExpires
        stainResistanceInHealthcareEnvironment
        stainResistanceInHealthcareEnvironmentCertificateFile
        stainResistanceInHealthcareEnvironmentCertificateFileExpires
        stainResistanceInHealthcareEnvironmentNotExpires
        ruleMed
        certificateDomain
    }
`;

export default singlePatternOtherAttributesFragment;