import React from "react";
import { Button, Input, InputGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import proquinalHistory from "services/browser-history";
import "./styles/SearchProjectsHeader.scss";

const INPUT_ID = "input-search";

const SearchProjectsHeader = () => {
  const handleSearch = () => {
    const el = document.getElementById(INPUT_ID);

    proquinalHistory.push({
      pathname: "/projects/list",
      search: `?keyword=${el.value}`,
    });
  };

  return (
    <div className="container py-5 mb-4">
      <h1 className="search--header-title font-weight-bold text-secondary">
        <FormattedMessage
          id="Projects.Home.Search.title"
          defaultMessage="CUSTOM YOUR SEARCH"
        />
      </h1>
      <div className="row align-items-end">
        <div className="col-12 col-lg-5">
          <span className="text-secondary fw-light search--header-description mt-3 d-block">
            <FormattedMessage
              id="Projects.Home.Search.description"
              defaultMessage="Filter each project according to your needs."
            />
          </span>
        </div>

        <div className="col-12 col-lg-7 float-lg-right my-2 my-lg-0 pl-lg-5">
          <InputGroup className="search--header-container-input">
            <Input
              id={INPUT_ID}
              className="search--header-input"
              placeholder="Search by leather, textile, contract..."
            />
            <Button
              className="w-25 search--header-button home-button-more-info"
              onClick={handleSearch}
            >
              <span className="font-weight-bold">
                <FormattedMessage
                  id="HeaderSearchBox.inputPlaceholder"
                  defaultMessage="Search"
                />
              </span>
            </Button>
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default SearchProjectsHeader;
