import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "reactstrap";
import { REASONS_TO_USE_PROJECT } from "./constants";
import { Link } from "react-router-dom";
import "./styles/DownloadImagesModal.scss";
import { FormattedMessage } from "react-intl";
import closeIcon from "./img/x.png";

const DownloadImagesModal = ({ isOpen, toggle, handleDownload }) => (
  <Modal isOpen={isOpen} toggle={toggle} size="lg">
    <div className="p-4 text-secondary">
      <div className="border border-dark donwload--images-modal__container-border">
        <div className="d-flex donwload--images-modal__container-close-button justify-content-end m-0 p-3">
          <div
            className="donwload--images-modal__close-button  rounded-circle d-flex justify-content-center align-items-center p-2"
            onClick={toggle}
          >
            <img
              src={closeIcon}
              alt="close-icon"
              width="15px"
              height="15px"
              className="m-1"
            />
          </div>
        </div>
        <div className="donwload--images-modal__container">
          <h4 className="font-weight-bold pb-3">
            <FormattedMessage
              id="DownloadProjectImages.legalConditionsTitle"
              description="LEGAL CONDITIONS + DISCLAIMERS"
            />
          </h4>

          <p className="donwload--images-modal__description">
            <FormattedMessage
              id="DownloadProjectImagesModal.legalConditionsDescriptionProperty"
              description="Project Library"
              defaultMessage="All the images and videos contained in this Project Library are the intellectual property of Spradling<sup>®</sup> and/or third parties. Access to and downloading of these contents are subject to the following terms and conditions:"
              values={{
                sup: (chunks) => <sup>{chunks}</sup>,
              }}
            />
          </p>

          <ul className="mb-4">
            <li className="pt-3 pb-3 donwload--images-modal__description">
              <FormattedMessage
                id="DownloadProjectImagesModal.legalConditionsDescriptionPermittedUse"
                description="Permission"
                defaultMessage="<bold>Permitted Use:</bold> Permission is granted to download and use the images and videos from this library exclusively for the purpose of promoting and marketing Spradling® products on platforms such as social media and/or client presentations. Any other use is strictly prohibited."
                values={{
                  bold: (chunks) => <strong>{chunks}</strong>,
                  sup: (chunks) => <sup>{chunks}</sup>,
                }}
              />
            </li>
            <li className="donwload--images-modal__description">
              <FormattedMessage
                id="DownloadProjectImagesModal.legalConditionsDescriptionAttribution"
                description="Attribution"
                defaultMessage="<bold>Attribution:</bold> Any use of these images and videos must include clear attribution to the end customer and/or associated brand."
                values={{
                  bold: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </li>
          </ul>

          <p className="donwload--images-modal__description">
            <FormattedMessage
              id="DownloadProjectImagesModal.legalConditionsDescriptionGlobalAuthorization"
              description="Is mandatory to have a global authorization to avoid limiting the uses to the market
                  where the project was carried out."
            />
          </p>

          <p className="donwload--images-modal__description">
            <FormattedMessage
              id="DownloadProjectImagesModal.legalConditionsDescriptionLegalAction"
              description="Any unauthorized use of these images"
              defaultMessage="Any unauthorized use of these images and videos may be subject to legal action. If you have any questions about permitted use, please contact the Spradling<sup>®</sup> marketing team."
              values={{
                sup: (chunks) => <sup>{chunks}</sup>,
              }}
            />
          </p>

          <div className="col-12 bg-light p-3 mt-4">
            <h5 className="d-inline font-weight-bold">
              <FormattedMessage
                id="DownloadProjectImagesModal.legalConditionsDescriptionIntentedUse"
                description="What is your intended use for this project?*"
              />
            </h5>

            <small style={{ fontSize: ".56rem" }}>
              <FormattedMessage
                id="DownloadProjectImagesModal.legalConditionsDescriptionYouCan"
                description="*You can select more than one option"
              />
            </small>

            <div className="d-lg-flex flex-wrap justify-content-between py-3">
              {REASONS_TO_USE_PROJECT.map(({ id, reason }) => {
                return (
                  <label key={`reason-use-project_${id}`} className="col-lg-4">
                    <input
                      type="checkbox"
                      name={reason}
                      value={reason}
                      className="mr-2"
                    />
                    {reason}
                  </label>
                );
              })}
            </div>
          </div>

          <div className="text-center mt-5">
            <Link to="/project/detail/download/success">
              <Button
                className="font-weight-bold home-button-more-info pt-1 pb-1 col-12 col-md-6 col-lg-4 mx-auto donwload--images-modal__button-download"
                onClick={() => handleDownload()}
              >
                <span style={{ fontSize: "1rem" }}>
                  <FormattedMessage id="Download" />
                </span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

DownloadImagesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  patternId: PropTypes.string.isRequired,
};

export default DownloadImagesModal;
