import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./styles/SpecsTableColumn.scss";

export default function SpecsTableColumn({value}) {
  const isLogo = typeof value === "object" && value.logo && value.title

  if (isLogo)
    return <img src={value?.logo} alt={value?.title} className="specs-table-image"/>;

  return <span>{value}</span>;
}

SpecsTableColumn.propTypes = {
  value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
  ]).isRequired
};
