import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { Query } from "react-apollo";
import { CATEGORIES_OPTIONS_TO_SEARCH } from "./constants/categoriesOptionsToSearch";
import ProjectCategoryItem from "./components/ProjectCategoryItem";

const ProjectsByCategory = () => {
  return (
    <>
      {CATEGORIES_OPTIONS_TO_SEARCH.map((category, i) => (
        <>
          {!category?.optionsQuery ? (
            <ProjectCategoryItem
              key={`item_${category.id}`}
              {...category}
              imgLeft={i % 2}
            />
          ) : (
            <Query key={`item_${category.id}`} query={category.optionsQuery}>
              {({ loading, error, data }) => {
                if (loading || error) return null;

                const resultData = {
                  ...category,
                  options: category.handleOptions(data[category.id]),
                };

                return (
                  <ProjectCategoryItem
                    key={category.id}
                    {...resultData}
                    imgLeft={i % 2}
                  />
                );
              }}
            </Query>
          )}
        </>
      ))}
      <div className="text-center projects-category__btn-view-projects-container">
        <Link to={"projects/list"}>
          <Button className="font-weight-bold home-button-more-info mx-auto py-2 px-md-5">
            <FormattedMessage
              id="Projects.Home.btn"
              defaultMessage="View all projects"
            />
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ProjectsByCategory;
