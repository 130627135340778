import React from "react";
import { FormattedMessage } from "react-intl";
import {
  referencesQuery,
  businessUnitsQuery,
  citiesQuery,
  countriesQuery,
  segmentsQuery,
} from "../queries";
import { AUTHORIZATION_PROJECT } from "scenes/ProjectDetails/components/ProjectDetailsHeaderInfo/constants";

export const CATEGORIES_FILTER_ID = {
  products: "referencesAvailableForProjectSearch",
  instances: "businessUnitAvailableForProjectSearch",
  cities: "citiesAvailableForProjectSearch",
  countries: "countryAvailableForProjectSearch",
  authorizations: "authorizations",
  marketSegments: "marketSegments",
};

const OPTIONS_FOR_ATHORIZATION = {
  total: {
    label: (
      <FormattedMessage
        id="ProjectDetailsFilter.totalAuthorization"
        description="Content permitted total for use"
        defaultMessage="Total authorization"
      />
    ), 
    value: AUTHORIZATION_PROJECT.TOTAL_AUTHORIZATION
  },
  partial: {
    label: (
      <FormattedMessage
        id="ProjectDetailsFilter.onlyInternalUse"
        description="Content permitted for internal use"
        defaultMessage="Only for internal use"
      />
    ),
    value: AUTHORIZATION_PROJECT.ONLY_INTERN_USE
  },
  none: {
    label: (
      <FormattedMessage
        id="ProjectDetailsFilter.withoutAuthorization"
        description="Content not permitted for use"
        defaultMessage="Without authorization"
      />
    ),
    value: AUTHORIZATION_PROJECT.WITHOUT_AUTHORIZATION
  }
};

export const CATEGORIES = [
  {
    id: CATEGORIES_FILTER_ID.products,
    name: "products",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.product"
        key="product"
        defaultMessage="Product"
      />
    ),
    optionsQuery: referencesQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.instances,
    name: "instances",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.bussines"
        key="bussines"
        defaultMessage="Business Unit"
      />
    ),
    optionsQuery: businessUnitsQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.cities,
    name: "city",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.city"
        key="city"
        defaultMessage="City"
      />
    ),
    optionsQuery: citiesQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.marketSegments,
    name: "marketSegments",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.segment"
        key="segment"
        defaultMessage="Segment"
      />
    ),
    optionsQuery: segmentsQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.authorizations,
    name: "authorizations",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.authorization"
        key="authorizations"
        defaultMessage="Authorization"
      />
    ),
    options: [
      {
        id: OPTIONS_FOR_ATHORIZATION.total.value,
        name: OPTIONS_FOR_ATHORIZATION.total.label,
      },
      {
        id: OPTIONS_FOR_ATHORIZATION.partial.value,
        name: OPTIONS_FOR_ATHORIZATION.partial.label,
      },
      {
        id: OPTIONS_FOR_ATHORIZATION.none.value,
        name: OPTIONS_FOR_ATHORIZATION.none.label,
      },
    ],
  },
];
