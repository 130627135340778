import { OPTIONS_LABEL_FOR_INSTANCES } from "scenes/SearchProjects/components/ProjectsByCategory/constants/categoriesOptionsToSearch";
import { LATAM_INSTANCES } from ".";

// NOTE - Mix ids instances according region
/**
 * @param {Array<Object>} options - The list of options to filter. Each option can be an object with properties like `code`, `node`, etc.
 * @param {Object} currentOption - The current option being evaluated. Must have a `code` property for comparison.
 * @param {string} regionCode - The region code used to match `currentOption.code` for filtering purposes.
 * 
 * @returns {Array<string>} - A list of IDs of the filtered instances. Each ID corresponds to an option that meets the
 *                            filtering criteria based on the region code.
 */
export const handleMixInstancesAccordingCode = (options, currentOption, regionCode) => {
  const instanceIds = [];

  if (currentOption.code === regionCode) {
    const filteredOptions = options.filter((option) => {
      const codeToCheckOption = option?.node ? option?.node : option

      const instancesList = regionCode === OPTIONS_LABEL_FOR_INSTANCES[1].key ? [...LATAM_INSTANCES, "eu"] : LATAM_INSTANCES;
      return regionCode === OPTIONS_LABEL_FOR_INSTANCES[1].key
        ? !instancesList.some((instance) => codeToCheckOption.code === instance)
        : instancesList.some((instance) => codeToCheckOption.code === instance);
    });

    filteredOptions.forEach((currentInstance) => {
      const codeToCheckInstance = currentInstance?.node ? currentInstance.node : currentInstance

      instanceIds.push(codeToCheckInstance.id);
    });
  }

  return instanceIds;
}